"use client";

import {
  PayloadLexicalReactRenderer,
  type PayloadLexicalReactRendererContent,
  type UploadNode,
  defaultElementRenderers,
} from "@atelier-disko/payload-lexical-react-renderer";
import Image from "next/image";

import { env } from "~/env.mjs";
import { getBlurData } from "~/lib/blurhash";
import { cn } from "~/lib/utils";

interface LexicalProps {
  content: unknown;
  noProse?: boolean;
  className?: string;
}

export function PayloadLexicalRender({
  content,
  noProse,
  className,
}: LexicalProps) {
  const checkedContent: unknown =
    typeof content === "string" ? JSON.parse(content) : content;
  return (
    <div
      className={cn("max-w-none", className, {
        "prose dark:prose-invert lg:prose-xl prose-headings:pt-4": !noProse,
      })}
    >
      <PayloadLexicalReactRenderer
        content={checkedContent as PayloadLexicalReactRendererContent}
        elementRenderers={{
          ...defaultElementRenderers,
          upload: (props: UploadNode) => {
            const image = props.value as UploadNode["value"] & {
              blurhash?: string | null;
            };
            if (!image?.url) {
              return null;
            }
            const url = `${env.NEXT_PUBLIC_PAYLOAD_URL}${image.url}`;
            const { width: clampedWidth, height: clampedHeight } =
              clampDimensions(image.width, image.height, 1920 / 2, 1080 / 2);
            return (
              <a href={url} target="_blank" rel="noopener noreferrer">
                <Image
                  src={url}
                  alt={image.alt}
                  width={clampedWidth}
                  height={clampedHeight}
                  blurDataURL={getBlurData(image)}
                  placeholder="blur"
                />
              </a>
            );
          },
        }}
      />
    </div>
  );
}

function clampDimensions(
  imageWidth: number | undefined,
  imageHeight: number | undefined,
  maxWidth: number,
  maxHeight: number,
) {
  if (!imageWidth || !imageHeight) {
    return { width: imageWidth, height: imageHeight };
  }

  const aspectRatio = imageWidth / imageHeight;

  let clampedWidth = imageWidth;
  let clampedHeight = imageHeight;

  if (clampedWidth > maxWidth) {
    clampedWidth = maxWidth;
    clampedHeight = maxWidth / aspectRatio;
  }

  if (clampedHeight > maxHeight) {
    clampedHeight = maxHeight;
    clampedWidth = maxHeight * aspectRatio;
  }

  return { width: clampedWidth, height: clampedHeight };
}
