"use client";

import { Link } from "~/components/link";
import { env } from "~/env.mjs";
import { cn } from "~/lib/utils";
import { type VideoMp4AndTextEmbedBlock } from "~/payload-types";

import { VideoEmbed } from "../video-embed";
import { PayloadLexicalRender } from "./RichText";

/* eslint-disable @next/next/no-img-element */

type Props = Omit<VideoMp4AndTextEmbedBlock, "blockName">;

export function VideoMp4AndTextEmbedBlockRender({
  title,
  description,
  page: _page,
  video: _video,
  reverseOrder,
}: Props) {
  const video = typeof _video === "number" ? undefined : _video;
  const page = typeof _page === "number" ? undefined : _page;
  if (!video) {
    return null;
  }
  return (
    <div
      className={cn(
        "mx-auto flex max-w-7xl flex-col gap-8 sm:gap-16 sm:px-6 lg:flex-row lg:items-center lg:px-8",
        {
          "lg:flex-row-reverse": !!reverseOrder,
        },
      )}
    >
      <h2 className="font-heading text-3xl tracking-tight sm:hidden">
        {title}
      </h2>
      <VideoEmbed
        src={`${env.NEXT_PUBLIC_PAYLOAD_URL}${video.url}`}
        className="aspect-[3/2] h-full w-full rounded-xl border shadow-lg"
      />

      <div className="w-full flex-auto">
        <h2 className="font-heading hidden text-5xl tracking-tight sm:block">
          {title}
        </h2>
        <PayloadLexicalRender
          content={description}
          className="mt-6 text-lg leading-8"
        />
        {page && (
          <div className="mt-10 flex">
            <Link
              href={page.slug}
              className="text-primary text-sm font-semibold leading-6"
            >
              Läs mer <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
