"use client";

import { useTheme } from "next-themes";
import { useIsClient } from "usehooks-ts";

import { cn } from "~/lib/utils";

interface Props {
  children: React.ReactNode;
  fullHeight?: boolean | null;
  backgroundColor?: string | null;
  backgroundColorDarkMode?: string | null;
}

export function SectionWrapper({
  children,
  fullHeight,
  backgroundColor,
  backgroundColorDarkMode,
}: Props) {
  const { theme } = useTheme();
  const isClient = useIsClient();

  return (
    <div
      className={cn("bg-background", {
        "min-h-screen": !!fullHeight,
      })}
      style={{
        backgroundColor: isClient
          ? theme === "dark"
            ? (backgroundColorDarkMode ?? undefined)
            : (backgroundColor ?? undefined)
          : undefined,
      }}
    >
      {children}
    </div>
  );
}
